import React from "react";
import Layout from "../components/Layout";
import Helmet from "react-helmet";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import '../styles/404.css'

export default function Home() {

  return (
    <Layout>
        <Helmet>
            <title>Thank You!</title>
            <meta 
              name="description" 
              content="You should recieve your Contact Card shortly." 
            />
            <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <div id="header">
				<Link to="/" title="Where Do You Go When Preparing For An Emergency?">
        <StaticImage
                src="../images/logo.png"
                placeholder="none"
                layout="fixed"
                width={225}
                alt="Dignity Health logo"
                formats={["auto", "webp", "avif"]}/>
				</Link>
			</div>
        <div id="notfound">
            <div className="notfound">
                <h1>Thanks!</h1>
                <h3>We sent your nearest Urgent Care's contact card via text to the provided phone number.</h3>
                <Link to="/">Back To Homepage</Link>
            </div>
        </div>
    </Layout>
  )
};